<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Produit" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail du produit</div>
              </v-row>
            </v-card-title>

            <!-- la catégorie de produit -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">catégorie de produit</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner une catégorie"
                    :items="categoryProduct"
                    item-text="label"
                    no-data-text="aucune catégorie"
                    v-model="selectedCategory"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom digital</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field 
                    dense 
                    v-model="digitalName" 
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            
            <!-- le nom commercial -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom commercial</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="commercialName"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            
            <!-- l'activité -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">activité</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner une activité"
                    :items="availableActivities"
                    item-text="name"
                    no-data-text="aucune activité"
                    v-model="selectedActivity"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            
            <!-- la sous-activité -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">sous-activité</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner une sous-activité"
                    :items="availableSubActivities"
                    item-text="label"
                    no-data-text="aucune sous-activité"
                    v-model="selectedSubActivity"
                    return-object
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- la spécialité médicale -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="label-field capitalize">spécialité médicale</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner une spécialité médicale"
                    :items="medicalSpecialities"
                    item-text="name"
                    no-data-text="aucune spécialité médicale"
                    v-model="selectedMedicalSpeciality"
                    return-object
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

             <!-- La description -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular">description</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-textarea dense v-model="description"></v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les description spécifiques -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="start">
                  <div class="label-field capitalize">
                    description(s) spécifique
                  </div>
                </v-col>
                <v-col cols="8" align-self="start">
                  <div v-for="(specificDescription, i) in specificDescriptions" :key="i">
                    <v-row no-gutters align="start" justify="space-between">
                      <v-col cols="4" class="px-1 pt-1">
                        <div>
                          {{ specificDescription.establishmentName }}
                        </div>
                      </v-col>

                      <v-col cols="7" class="px-1" >
                        <v-textarea 
                        dense 
                        v-model="specificDescription.description">
                        </v-textarea>
                      </v-col>

                      <v-col cols="1" class="px-1">
                        <v-btn
                          icon
                          color="primary"
                          @click="removeSpecificDescription(i)"
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters align="start" justify="space-between">
                      <v-col cols="4" class="px-1">
                        <v-autocomplete
                          v-model="selectedEstablishment"
                          :items="availableEstablishment"
                          item-text="digitalName"
                          return-object
                          placeholder="Associer un établissement"
                          hint="Associer un établissement, une description et appuyez sur '+'"
                          clearable
                          no-data-text="aucun établissement"
                        >
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="7" class="px-1">
                        <v-textarea
                            placeholder="Ajouter une description"
                            v-model="descriptionEstablishment"
                        ></v-textarea>
                      </v-col>

                      <v-col cols="1" align="center" class="pt-2">
                        <v-btn
                          color="primary"
                          icon
                          @click="addSpecificDescription()"
                          :disabled="!canAddSpecificDescription"
                          ><v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Fenetre modale pour éviter que l'utilisateur quitte sans sauvegarder -->
    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { AvecProductsService } from "@/service/conf/avec_products_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import SubActivitiesService from "@/service/sfr/sub_activity_service.js";
import { MedicalSpecialtiesService } from "@/service/practitioners/medical_specialties_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { AvecProductCategoryService } from "@/service/conf/avec_product_category_service.js"

import { areTheSame, trimString } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditAvecProducts",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /** le service des entités */
      service: null,
      /** le service des activités */
      serviceActivities: null,
      /** le service des sous-activités */
      serviceSubActivities: null,
      /** le service des spécialités médicales */
      serviceMedicalSpecialities: null,
      serviceEstablishment: null,
      serviceCategoryProduct: null,


      /**en cours de chargement. */
      loading: false,

      /**La donnée originale */
      source: null,

      /** le nom digital */
      digitalName: null,
      /** le nom commercial */
      commercialName: null,
      /** la description */
      description: null,

      /** la liste d'activités */
      activities: [],
      /** l'activité sélectionnée */
      selectedActivity: null,

      /** la liste de sous-activités */
      subActivities: [],
      /** la sous-activité sélectionné */
      selectedSubActivity: null,

      /** la liste des activité avec leurs sous-activités */
      activitiesWithSubAct: [],

      /** la liste de spécialité médicale */
      medicalSpecialities: [],
      /** la spécialité médicale sélectionnée */
      selectedMedicalSpeciality: null,

      /** La liste des descriptions spécifiques */
      specificDescriptions: [],
      /** La liste source des descriptions spécifiques */
      specificDescriptionsSource: [],

      /** La liste des établissement du groupe */
      establishments: [],
      /** L'établissement pour l'ajout d'une description spécifique */
      selectedEstablishment: null,
      /** La description pour l'établissement */
      descriptionEstablishment: null,

      /** La liste des catégorie de produit */
      categoryProduct: [],
      /** La catégorie sélectionnée */
      selectedCategory: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération de l'entité
        let entity = await this.service.getById(this.entityId);
        
        // Récupération des catégories de produit
        this.categoryProduct = await this.serviceCategoryProduct.getAll();
        // Récupération de toutes les activités
        this.activities = await this.serviceActivities.getActivities();
        // Récupération de toutes les activités avec leurs sous-activités
        this.activitiesWithSubAct = await this.serviceActivities.getActivitiesWithSubactivities();
        // Récupération de toutes les sous-activités
        this.subActivities = await this.serviceSubActivities.getAllSubActivities();
        // Récupération de toutes les spécialités médicales
        this.medicalSpecialities = await this.serviceMedicalSpecialities.getAll();
        // Récupération de l'ensemble des noms d'établissement
        this.establishments = await this.serviceEstablishment.getDigitalNames();
        // Récupération de l'ensemble des descriptions spécifiques
        this.specificDescriptions = await this.service.getAllSpecificDescription(entity.id);

        // Récupération de la catégorie du produit
        if (entity.category) {
          this.selectedCategory = this.categoryProduct.find((c) => c.id == entity.category);
        }

        // Récupération de la sélection de l'activité
        if(entity.activity) {
          this.selectedActivity = this.activities.find((a) => a.id == entity.activity);
        }

        // Récupération de la sélection de la sous-activité
        if(entity.subActivity) {
          this.selectedSubActivity = this.subActivities.find((s) => s.id == entity.subActivity);
        }

        // Récupération de la sélection de la spécialité médicale
        if(entity.medicalSpeciality) {
          this.selectedMedicalSpeciality = this.medicalSpecialities.find((m) => m.id == entity.medicalSpeciality);
        }

        // Récupération du nom de l'établissement pour toutes les descriptions spécifiques
        for (let speDesc of this.specificDescriptions) {
          let foundEstab = this.establishments.find((e) => e.id == speDesc.establishmentid);

          if (foundEstab) {
            speDesc.establishmentName = foundEstab.digitalName;
          }
        }
        this.specificDescriptionsSource = JSON.parse(JSON.stringify(this.specificDescriptions));

        this.source = JSON.parse(JSON.stringify(entity));

        this.init();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Initialisation des datas de l'entité */
    init() {
      this.digitalName = this.source.digitalName;
      this.commercialName = this.source.commercialName;
      this.description = this.source.description;
    },

    /** Sauvegarde des datas de l'entité */
    async save() {
      try {
        let element = JSON.parse(JSON.stringify(this.source));
        element.digitalName = this.digitalName;
        element.commercialName = this.commercialName;
        element.description = this.description;
        // Catégorie de produit
        element.category = this.selectedCategory.id;

        // Sauvegarde de l'activité sélectionnée ou remise à null si supprimée
        if (this.selectedActivity) {
          element.activity = this.selectedActivity.id;
        } else {
          element.activity = null;
        }

        // Sauvegarde de la sous-activité sélectionnée ou remise à null si supprimée
        if (this.selectedSubActivity) {
          element.subActivity = this.selectedSubActivity.id;
        } else {
          element.subActivity = null;
        }

        // Sauvegarde de la spécialité médicale sélectionnée ou remise à null si supprimée
        if (this.selectedMedicalSpeciality) {
          element.medicalSpeciality = this.selectedMedicalSpeciality.id;
        } else {
          element.medicalSpeciality = null;
        }  

        // Sauvegarde en base
        await this.service.update(element);

        this.source = JSON.parse(JSON.stringify(element));

        // Sauvegarde des descriptions spécifiques si elles ont changées
        for (let descriptionSpecific of this.specificDescriptions) {
          // Recherche des nouvelles descriptions ou des mises à jour
          let foundToSource = this.specificDescriptionsSource.find((s) => s.establishmentid == descriptionSpecific.establishmentid);


          if (!foundToSource || descriptionSpecific.description != foundToSource.description) {
            let desc = {};
            desc.description = descriptionSpecific.description;
            // Sauvegarde de chaque description spécifique
            await this.service.createUpdateSpecificDescription(descriptionSpecific.establishmentid, this.entityId, desc);
          }
        }
        // Recherche des suppression
        for (let descSpeSource of this.specificDescriptionsSource) {
          let found = this.specificDescriptions.find((s) => s.establishmentid == descSpeSource.establishmentid);

          if (!found) {
            await this.service.deleteSpecificDescription(descSpeSource.establishmentid, this.entityId);
          }
        }


        this.disableAlertQuit();
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },

    /** Suppression d'une description spécifique  */
    removeSpecificDescription(index) {
      if (index != null) {
        this.specificDescriptions.splice(index, 1);
      }
    },

    /** Ajoute une description spécifique pour un établissement */
    addSpecificDescription() {
      let desc = {};

      desc.establishmentName = this.selectedEstablishment.digitalName;
      desc.establishmentid = this.selectedEstablishment.id;
      desc.description = this.descriptionEstablishment;

      this.specificDescriptions.push(desc);

      this.selectedEstablishment = null;
      this.descriptionEstablishment = null;
    },

    /** Les descriptioons ont-elles changées */
    isSpecificDescriptionHasChanged() {
      let changed = false;

      // Parcours les description affichée et les compare à la source
      for (let descriptionSpe of this.specificDescriptions) {
        let foundToSource = this.specificDescriptionsSource.find((s) => s.establishmentid == descriptionSpe.establishmentid);

        if (!foundToSource || foundToSource.description != descriptionSpe.description) {
          return true;
        }
      }

      // Parcours les descriptions sources et retrouve les description supprimées
      for (let descriptionSpeSource of this.specificDescriptionsSource) {
        let found = this.specificDescriptions.find((s) => s.establishmentid == descriptionSpeSource.establishmentid);

        if (!found) {
          return true;
        }
      }

      return changed;
    },

        /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },

  },
  computed: {
    completed() {
      if (!this.digitalName) return false;
      if (!this.commercialName) return false;
      if (!this.selectedActivity) return false;
      if (!this.selectedCategory) return false;


      return true;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (this.selectedCategory.id != this.source.category) {
        changed = true;
      }

      if (!areTheSame(this.source.digitalName, this.digitalName.trim())) {
        changed = true;
      }

      if (!areTheSame(this.source.commercialName, this.commercialName)) {
        changed = true;
      }

      if (!areTheSame(this.source.description, this.description)) {
        changed = true;
      }

      if (this.selectedActivity) {
        if (this.source.activity != this.selectedActivity.id) {
          changed = true;
        }
      }

      // sous-activité choisie ? --> est-ce la même que la source ?
      if (this.selectedSubActivity) {
        if (this.source.subActivity != this.selectedSubActivity.id) {
          changed = true;
        }
      } else { // sous-activité à null --> la source est-elle aussi à null
        if (this.source.subActivity) {
          changed = true;
        }
      }
      
      // spécialité médicale choisie ? --> est ce la même que la source ?
      if (this.selectedMedicalSpeciality) {
        if (this.source.medicalSpeciality != this.selectedMedicalSpeciality.id) {
          changed = true;
        }
      } else { // 
        if (this.source.medicalSpeciality) {
          changed = true;
        }
      }

      // Les descriptions spécifiques ont changées ?
      if (this.isSpecificDescriptionHasChanged()) {
        changed = true;
      }    


      return changed;
    },

    /** Retourne les sous activités disponible en fonction de l'activité choisie */
    availableSubActivities() {
      let available = [];

      // Pas d'activité choisie, on retourne toute les sou act
      if (!this.selectedActivity) {
        available = JSON.parse(JSON.stringify(this.subActivities));
      } else {
        // Récupère l'activité dans le tableau d'association act / sous act
        let act = this.activitiesWithSubAct.find((a) => a.activityId == this.selectedActivity.id);
        available = JSON.parse(JSON.stringify(act.subActivities));

        // Tri des sous activités par label
        available.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
      }

      return available;
    },

    /** Retourne les activités disponible en fonction de la sous activité sélectionnée */
    availableActivities() {
      let available = [];

      // Pas de sous act sélectionnée --> on retourne toutes les activités
      if (!this.selectedSubActivity) {
        available = JSON.parse(JSON.stringify(this.activities));
      } else {
        let activitiesWithSelectedSubAct = [];
        for (let activity of this.activitiesWithSubAct) {
          // Récupère le tuple contenant la sous activité sélectionnée
          let found = activity.subActivities.find((s) => s.id == this.selectedSubActivity.id);

          if (found) {
            // Ajout de l'id de l'activité si elle est associé à la sous act sélectionnée
            activitiesWithSelectedSubAct.push(activity.activityId);
          }
        }
        // Filtre l'ensembles des activités pour ne garder que les activités trouvé précédemment
        available = this.activities.filter((a) => activitiesWithSelectedSubAct.includes(a.id));
      }

      return available;
    },

    /** Fournit la liste des établissement qui n'ont pas de description spécifique pour ce produit */
    availableEstablishment() {
      let available = [];

      let ids = this.specificDescriptions.map((s) => s.establishmentId);
      available = this.establishments.filter((e) => !ids.includes(e.id));

      return available;
    },

    /** Autorisation la création d'une description spécifique */
    canAddSpecificDescription() {
      if (!this.selectedEstablishment) return false;
      if (!this.descriptionEstablishment) return false;

      return true;
    },
  },
  mounted() {

    this.service = new AvecProductsService(this.$api.getTransmissionsAvecProductsApi());
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceSubActivities = new SubActivitiesService(this.$api);
    this.serviceMedicalSpecialities = new MedicalSpecialtiesService(
      this.$api.getMedicalSpecialitiesApi()
    );
    this.serviceEstablishment = new EstablishmentsService(this.$api);
    this.serviceCategoryProduct = new AvecProductCategoryService(this.$api.getProductCategory());


    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>